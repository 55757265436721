//
export default {
  name: 'app-layout-drawer',
  computed: {

    /**
     * 
     */
    _value: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
  },
  data() {
    return {
      dialogs: {
        logout: false,
      },
    }
  },
  methods: {

    /**
     * 
     */
    handleLogoutClick() {
      this.dialogs.logout = true
    },

    /**
     * 
     */
    async handleLogoutAcceptClick() {

      // reset auth module
      await this.$store.dispatch('AuthModule/reset')

      // reset provider module
      await this.$store.dispatch('ProviderModule/reset')

      // reset user module
      await this.$store.dispatch('UserModule/reset')

      // close dialog
      this.dialogs.logout = false

      // redirect
      this.$router.push('/')
    },

    /**
     * 
     */
    handleLogoutCancelClick() {
      this.dialogs.logout = false
    },
  },
  props: {

    /**
     * 
     */
    value: {
      type: Boolean,
    },
  },
}